import { LAUNCHPAD_MASTERCHEF_ABI } from "../Config/masterchefAbi";
import { ERC20_ABI } from "../Config/erc20";
import toast, { Toaster } from 'react-hot-toast';
import { style, iconTheme, position } from './useToast'
import { GetChainIndex, useWeb3, usehttpProvider } from "./UseWeb3"
import { CHAINS } from "../Config/env";
import BigNumber from 'bignumber.js';
import { getAccount } from "./useAccounts";
import axios from "axios";





export const UseMasterChef = async (VALUE) => {
    try {
        const web3 = await useWeb3();

        const contract = new web3.eth.Contract(LAUNCHPAD_MASTERCHEF_ABI, VALUE);
        return contract;
    }
    catch (e) {
        console.log("error", e);
    }
}


export const UseERC20 = async (VALUE) => {
    try {
        const web3 = await useWeb3();
        const Tokencontract = new web3.eth.Contract(ERC20_ABI, VALUE);
        return Tokencontract;
    } catch (e) {

    }
}


export const getUSDTAddress = () => {
    try {
        const index = GetChainIndex();
        const ContractAddress = CHAINS[index].USDTADDRESS;

        return ContractAddress;
    } catch (e) {

    }
}


export const UsegetSaleInfo = async (presaleAddress, account) => {
    
    try {
        const web3 = await useWeb3();
        const saleContract = await UseMasterChef(presaleAddress);
        const usdtaddress = await getUSDTAddress()
        const presaletoken = await saleContract.methods.tokenAddress().call();
        const presaletokenContract = await UseERC20(presaletoken);
        const erc20Contract = await UseERC20(usdtaddress);
        const UserusdttokenBalance = await erc20Contract.methods.balanceOf(account).call();
        const presaletokensymbol = await presaletokenContract.methods.symbol().call();
        const tokenpricePer = await saleContract.methods.tokenRatePerEth().call();

        const minamount = await saleContract.methods.minEthLimit().call() / 10 ** 18;
        const maxamount = await saleContract.methods.maxEthLimit().call() / 10 ** 18;
        const pricePerCrypto = await saleContract.methods.tokenRatePercrypto().call();
        const busdminamount = await saleContract.methods.BUSDminEthLimit().call() / 10 ** 18;
        const busdmaxamount = await saleContract.methods.BUSDmaxEthLimit().call() / 10 ** 18;
        const bnbearnedcap = await saleContract.methods.earnedCap().call();
        const bnbearnedcapRoundValue = UseRoundValue(bnbearnedcap, 18)
        const busdearnedcap = await saleContract.methods.BUSDearnedCap().call();
        const busdearnedcaproundvalue = UseRoundValue(busdearnedcap, 18);
        const allowance = await erc20Contract.methods.allowance(account, presaleAddress).call();
        const investedT = await saleContract.methods.getUserInvestments(account).call() / 10 ** 18;
        const busdinvestedT = await saleContract.methods.getUserBUSDInvestments(account).call() / 10 ** 18;
        const UserBNB = await web3.eth.getBalance(account) / 10 ** 18;
       
        //  const busdbalance = await erc20Token.methods.balanceOf(ADDRESS).call() / 10 ** 18;
        const claimb = await saleContract.methods.getUserClaimbale(account).call() / 10 ** 18;
        const soldT = await saleContract.methods.soldTokens().call() / 10 ** 18;

        const ispresaleopen = await saleContract.methods.isPresaleOpen().call();
        const isclaimable = await saleContract.methods.isClaimable().call();
        const hard = await saleContract.methods.hardCap().call();
        const bnbroundvalue = UseRoundValue(hard, 18)
        const busdhard = await saleContract.methods.BUSDhardCap().call();
        const busdhardroundvalue = UseRoundValue(busdhard, 18);
        const endD = await saleContract.methods.endTime().call();
        const date = new Date(parseInt(endD) * 1000);
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const saleEndTime = date.toLocaleDateString("en-US", options);

        const data = {
            pricePer: tokenpricePer,
            minamount: minamount,
            maxamount: maxamount,
            pricePerCrypto: pricePerCrypto,
            busdminamount: busdminamount,
            busdmaxamount: busdmaxamount,
            bnbearnedcap: bnbearnedcapRoundValue.toString(),
            busdearnedcap: busdearnedcaproundvalue.toString(),
            investedT:investedT,
            busdinvestedT:busdinvestedT,
            claimb: claimb.toString(),
            soldT: soldT,
            saleEndTime: saleEndTime,
            ispresaleopen: ispresaleopen,
            hardCap: bnbroundvalue,
            busdhard: busdhardroundvalue.toString(),
            isclaimable: isclaimable,
            usdtaddress: usdtaddress,
            UserusdttokenBalance: UserusdttokenBalance,
            presaletoken: presaletoken,
            UserBNB: UserBNB,
            allowance:allowance,
            presaletokensymbol:presaletokensymbol

        }

        return data;
    }
    catch (e) {
        console.log("UsegetSaleInfo", e);
    }
}




export const UseBuyToken = async (coinname, useramount, token, presaleAddress, account,isMax) => {
    
    console.log("amount",useramount);
        const web3 = await useWeb3();
       
        const erc20Contract = await UseERC20(token); 
        const symbol = await erc20Contract.methods.symbol().call();

        const saleContract = await UseMasterChef(presaleAddress);
        // const value = parseFloat(useramount)
        // const amount = parseFloat(useramount * 10 ** 18)
        const amount = !isMax? toFixedNumber(useramount * 10 **18): useramount ;
        // const amount = new BigNumber(value).times(new BigNumber(10).pow(18));
        console.log("amount",amount);
        // if (coinname === 'BNB') {
            
        // const data = await web3.eth.sendTransaction({ from: account, to: presaleAddress, value: amount.toString() })
        //        await toast.promise(data, {
        //         loading: `Buying ${symbol} token...`,
        //         success: 'Buy Successfully',
        //         error: 'Try Again',
        //     }, {
        //         position: position.position,
        //         style: style,
        //         iconTheme: iconTheme,
        //     })
        //      }
            //  else {
                
               

                 const data =  saleContract.methods.buyToken(amount.toString()).send({ from: account })
                 await toast.promise(data, {
                    loading: `Buying ${symbol} token...`,
                    success: 'Buy Successfully',
                    error: 'Try Again',
                }, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })

// }
        
   
}
export const sleep = (ms) => {
    new Promise(resolve => setTimeout(resolve, ms))
}

export const approveContract = async (account, token, presaleaddress) => {

    const erc20Contract = await UseERC20(token);
    const symbol = await erc20Contract.methods.symbol().call();
    const data = erc20Contract.methods.approve(presaleaddress, "115792089237316195423570985008687907853269984665640564039457584007913129639935").send({ from: account })
    await toast.promise(data, {
        loading: `Approving ${symbol} token...`,
        success: 'Approved Successfully',
        error: 'Try Again',
    }, {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
    })
}








export const Claim = async (account, presaleAddress) => {

    const saleContract = await UseMasterChef(presaleAddress);
    const data = saleContract.methods.claimTokens().send({ from: account });
    await toast.promise(data, {
        loading: 'Requesting for Claim Tokens...',
        success: 'Tokens Claimed Successfully',
        error: 'Error ! When Claiming Token',
    }, {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
    }
    );
}


export const toFixedNumber = (x) => {
    if (Math.abs(x) < 1.0) {
        var e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        var e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}



export const checkIsApproved = async (account, token, presaleAddress) => {
    try {

        const erc20Contract = await UseERC20(token);
        const allow = await erc20Contract.methods.allowance(account, presaleAddress).call();


        return (parseInt(allow) > 0) ? true : false;
    } catch (e) {

    }
}

export const UsergetToken = async (amount, coinname, token, presaleAddress) => {
    if (amount !== null && amount > 0 && amount !== "") {

        const saleContract = await UseMasterChef(presaleAddress);
        const erc20Contract = await UseERC20(token);
        let price = 0;
        const decimals = await erc20Contract.methods.decimals().call();

        if (coinname === "BNB") {
            const price = await saleContract.methods.getTokensPerEth(new BigNumber(amount).times(new BigNumber(10).pow(18)).toString()).call() / 10 ** parseInt(decimals);

            return price;
        } else {

            const price = await saleContract.methods.getTokenPerCrypto(new BigNumber(amount).times(new BigNumber(10).pow(18)).toString()).call() / 10 ** parseInt(decimals);
            return price;

        }
    }
}






export const searchedValue = async (ProposalList, searchSymbol) => {
    const filteredData = await ProposalList.filter(value => ((value.isActive == searchSymbol)));
    return filteredData;
}


export const searchedCloseProposal = async (ProposalList) => {
    const filteredData = await ProposalList.filter(value => ((!value.isActive === !value.status)));
    return filteredData;
}

const UseRoundValue = (VALUE, DECIMALS) => {
    return VALUE > 0 ? Math.round((new BigNumber(VALUE).div(new BigNumber(10).pow(DECIMALS))).toNumber()) : new BigNumber(0);

}


export const UsegetsingleSaleInfo = async (presaleAddress, account) => {
    
    try {
        const web3 = await useWeb3();
        const saleContract = await UseMasterChef(presaleAddress);
        
        const pricePerCrypto = await saleContract.methods.tokenRatePercrypto().call();
     

        const ispresaleopen = await saleContract.methods.isPresaleOpen().call();
        

        const data = {
            
            pricePerCrypto: pricePerCrypto,
           ispresaleopen: ispresaleopen,
            

        }

        return data;
    }
    catch (e) {
        console.log("UsegetSaleInfo", e);
    }
}